<template>
  <v-container fluid>
    <label class="label primary--text">
      <h3>Transformações e Mapa de Campo</h3>
    </label>
    <v-data-table
      class="elevation-1"
      :headers="columnHeadersComputed"
      :items="layout.columns"
      :single-expand="true"
      :items-per-page="-1"
      item-key="order"
      :show-group-by="isImport"
      show-expand
      hide-default-footer
      fixed-header
      hide-default-header
      :height="layout.columns && layout.columns.length > 10 ? 550 : null"
    >
    <template v-slot:no-data>
      <span>Não há dados</span>
    </template>
    <template v-slot:header="{ props }">
      <thead class="v-data-table-header">
        <tr class="table-header">
          <th style="background-color: #d9d9d9 !important; text-align: center;" v-for="header in props.headers" :key="header.text">
            <label class="label primary--text">
              {{ header.text }}
            </label>
          </th>
        </tr>
      </thead>
    </template>
    <template v-slot:[`item.fromTo`]="{ item }">
      <v-row justify="center" class="my-0">
        <v-col cols="12" lg="9" class="py-0 my-0">
          <v-autocomplete
            v-if="isImport && layout.typeIntegrationValue === 'SHIPPING'"
            v-model="item.fromTo"
            :items="propsFromToFields"
            item-text="label"
            item-value="value"
            placeholder="Informe"
            class="mt-6"
            color="textPrimary"
            dense
            solo
            :rules="[rule.required]"
            validate-on-blur
          />
          <v-text-field
            v-if="isImport && (layout.typeIntegrationValue === 'RECEIVEMENT' || layout.typeIntegrationValue === 'PAYROLL')"
            v-model="item.name"
            placeholder="Informe"
            class="mt-6"
            color="textPrimary"
            dense
            solo
            readonly
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <v-row v-if="isImport" justify="center" class="my-0">
        <v-col cols="12" lg="9" align="center" class="py-0 my-0">
          <v-autocomplete
            v-if="isImport && (layout.typeIntegrationValue === 'RECEIVEMENT' || layout.typeIntegrationValue === 'PAYROLL')"
            v-model="item.fromTo"
            :items="propsFromToFields"
            item-text="label"
            item-value="value"
            placeholder="Informe"
            class="mt-6"
            color="textPrimary"
            dense
            solo
            :rules="[rule.required]"
            validate-on-blur
          />
          <v-text-field
            v-if="isImport && layout.typeIntegrationValue === 'SHIPPING'"
            v-model="item.name"
            placeholder="Informe"
            class="mt-6"
            color="textPrimary"
            dense
            solo
            readonly
          />
        </v-col>
      </v-row>
      <span>{{ !isImport ? item.name : null }}</span>
    </template>
    <template v-slot:[`item.type`]="{ item }">
      <span>{{ item.type === 'ALPHANUMERIC' ? 'Alfanumérico' : item.type === 'NUMERIC' ? 'Numérico' : 'Data' }}</span>
    </template>
    <template v-slot:expanded-item="{ item }">
      <td :colspan="columnHeaders.length" class="mx-0 px-0">
        <v-row class="pa-0 ma-0">
          <v-col class="px-0 py-0" cols="12">
            <v-data-table
              class="elevation-1 px-0 pb-1"
              :headers="transformationHeaders"
              :items="item.transformations"
              :items-per-page="-1"
              loading-text="Carregando..."
              dense
              hide-default-footer
            >
              <template v-slot:no-data>
                <span>Não há dados</span>
              </template>
              <template v-slot:[`item.isChecked`]="{ item: itemChild }">
                <td :class="itemChild.isChecked ? 'isSelectedTransformation' : ''">
                  <v-row class="justify-center">
                    <v-checkbox
                      color="textPrimary"
                      :disabled="isVisualization || isToDisableCheck(itemChild, item) || concatFunctionValidationBtn(itemChild)"
                      v-model="itemChild.isChecked"
                      @change="() => isValidOrder = rule.checkRepeatOrders(itemChild.order, item.transformations)"
                      @click="itemChild.isModal && itemChild.isChecked ? openModalTransformations(itemChild, item) : !itemChild.isModal && itemChild.isChecked ? checkParameterInTransformation(itemChild, item) : itemChild.order = ''"
                    />
                  </v-row>
                </td>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <td align="center" :class="item.isChecked ? 'isSelectedTransformation font-weight-bold' : ''">
                  {{ item.name }}
                </td>
              </template>
              <template v-slot:[`item.actions`]="{ item: itemChild }">
                <td align="center" :class="itemChild.isChecked ? 'isSelectedTransformation' : ''">
                  <v-menu bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-if="itemChild.isModal" :disabled="itemChild.isChecked ? false : true" @click="openModalTransformations(itemChild, item)" v-bind="attrs" v-on="on" icon color="primary">
                        <v-icon color="textPrimary">mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </template>
                  </v-menu>
                </td>
              </template>
              <template v-slot:[`item.order`]="{ item: itemChild }">
                <td align="center" :class="itemChild.isChecked ? 'isSelectedTransformation' : ''">
                  <v-row>
                    <v-text-field
                      v-if="itemChild.isChecked"
                      placeholder="Informe"
                      class="mt-6 pr-4"
                      color="textPrimary"
                      v-model="itemChild.order"
                      v-mask="'####'"
                      dense
                      solo
                      autofocus
                      :disabled="isVisualization || (itemChild.isChecked && itemChild.template === 'concatenar_campos')"
                      :rules="[rule.required, rule.checkRepeatOrders(itemChild.order, item.transformations)]">
                      <template v-slot:prepend>
                      <v-tooltip
                        v-if="itemChild.isChecked && itemChild.template === 'concatenar_campos'"
                        bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        Por padrão, está função sempre será executada primeiro.
                      </v-tooltip>
                    </template>
                    </v-text-field>
                  </v-row>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </td>
    </template>
    </v-data-table>

    <Transformations
      ref="transformationModal"
      :propsTransformationParameter="transformationParameter"
      @on-confirm="addColumnFunctionFromModal"
    />
    <TransformationsFromTo
      ref="transformationsFromToModal"
      :propsTransformationParameter="transformationParameter"
      @on-confirm="addColumnFunctionFromModal"
    />
    <TransformationsFromToAdvanced
      ref="transformationsFromToAdvancedModal"
      :propsTransformationParameter="transformationParameter"
      :layout="layout"
      :dataSourceColumns="propsFromToFields"
      @on-confirm="addColumnFunctionFromModal"
    />

    <TransformationsConcatColumnModal
      ref="transformationsConcatColumnModal"
      :propsTransformationParameter="transformationParameter"
      :layout="layout"
      :dataSourceColumns="propsFromToFields"
      @on-confirm="addColumnFunctionFromModal"
    />

    <SnackbarCustomize ref="SnackbarCustomize" />
  </v-container>
</template>

<script>
import Rules from '@/shared/validators/formRules';
import Transformations from '@/components/Layout/Transformations/Modals/transformationsModal.vue';
import TransformationsFromTo from '@/components/Layout/Transformations/Modals/transformationsFromToModal.vue';
import TransformationsFromToAdvanced from '@/components/Layout/Transformations/Modals/transformationsFromToAdvancedModal.vue';
import TransformationsConcatColumnModal from '@/components/Layout/Transformations/Modals/transformationsConcatColumnModal.vue';
import lodash from 'lodash';
import FunctionService from '@/services-http/sdi/FunctionService';
import DataSourceService from '@/services-http/sdi/DataSourceService';
import { observableMutationsRefreshToken } from '@/shared/observable/loadingRefreshToken';
import AuthService from '@/services-http/auth/AuthService';
import UserService from '@/services-http/security/UserService';
import UserUtils from '@/shared/utils/user-utils';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';

export default ({
  components: {
    Transformations,
    TransformationsFromTo,
    TransformationsFromToAdvanced,
    TransformationsConcatColumnModal,
    SnackbarCustomize,
  },

  data: () => ({
    layout: {},
    transformationParameter: {},
    isVisualization: false,
    snackbar: {
      type: '',
      show: false,
      text: '',
    },
    transformationFunctions: [],
    columnHeaders: [
      {
        text: 'Campo',
        value: 'order',
        align: 'center',
        sortable: false,
      },
      {
        text: '"DE"',
        value: 'fromTo',
        align: 'center',
        sortable: false,
      },
      {
        text: '"PARA"',
        value: 'name',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Nome',
        value: 'name',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Tipo',
        value: 'type',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Transformações',
        value: 'data-table-expand',
        align: 'center',
        sortable: false,
      },
    ],
    transformationHeaders: [
      {
        text: 'Selecionar',
        value: 'isChecked',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Nome',
        value: 'name',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Ordem',
        value: 'order',
        align: 'center',
        sortable: false,
        width: '15%',
      },
    ],

    SESSION_STORAGE_TOKEN: '@auth/token',
    SESSION_ODOO_TOKEN: '@auth-odoo/token',
  }),

  props: {
    propsLayout: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    isCopy: {
      type: Boolean,
      required: true,
    },
    isImport: {
      type: Boolean,
      required: true,
    },
    propsFromToFields: Array,
  },

  watch: {
    propsLayout: {
      handler(val) {
        if (val) {
          this.layout = val;
          this.loadTransformationFunctions();
        }
      },
    },
  },

  mounted() {
    if (sessionStorage.getItem('visualization')) {
      this.isVisualization = true;
    }
  },

  computed: {
    columnHeadersComputed() {
      if (this.isImport) {
        return this.columnHeaders.filter((element) => element.text !== 'Nome');
      }
      return this.columnHeaders.filter((element) => element.value !== 'fromTo' && element.text !== '"PARA"');
    },
  },

  methods: {
    async loadTransformationFunctions() {
      try {
        const response = await this.functionService.FindAll();

        if (response && response.data) {
          this.transformationFunctions = response.data;

          const deParaTransformation = this.transformationFunctions.find((element) => element.id === 'depara');
          deParaTransformation.parameters = [{ isField: false, name: 'Campo', order: 1 }];

          const deParaAdvancedTransformation = this.transformationFunctions.find((element) => element.id === 'depara_avancado');
          deParaAdvancedTransformation.parameters = [];

          const concatColumnTransformation = this.transformationFunctions.find((element) => element.id === 'concatenar_campos');
          concatColumnTransformation.parameters = [];

          this.addCheckInTransformations();
          this.transformationFunctions.sort((a, b) => this.sortByParams(a, b));
          await this.incrementTransformationPattern();
        }
      } catch (error) {
        this.$refs.SnackbarCustomize.open('error', 'Erro ao carregar as funções de transformação');
      }
    },
    sortByParams(a, b) {
      if (a.isModal > b.isModal) return 1;
      if (a.isModal < b.isModal) return -1;
      return 0;
    },
    addCheckInTransformations() {
      const transformation = this.transformationFunctions.map((element) => ({
        id: element.id,
        name: element.name,
        isChecked: false,
        isModal: element.name === 'Depara Avançado' || element.id === 'concatenar_campos' || element.parameters.some((param) => !param.isField),
        parameters: element.parameters,
      }));
      this.transformationFunctions = transformation;
      this.addIsAlphanumericParameter();
    },
    addIsAlphanumericParameter() {
      this.transformationFunctions.forEach((transformation) => {
        if (transformation.isModal) {
          transformation.parameters.forEach((parameter) => {
            // eslint-disable-next-line no-param-reassign
            parameter.isAlphanumeric = false;
          });
        }
      });
    },
    async incrementTransformationPattern() {
      this.layout.columns.forEach((element, index) => {
        this.layout.columns[index].transformations = lodash.cloneDeep(this.transformationFunctions);
      });
      this.$emit('overlayControll', false);
      if (this.isEdit || this.isCopy || this.isImport) {
        await this.selectLoadedTransformations();
      }
    },
    async checkParameterInTransformation(itemChild, item) {
      await this.checkToken();
      if (itemChild || !itemChild.field) {
        // eslint-disable-next-line no-param-reassign
        itemChild.field = item.name;
      }
      // eslint-disable-next-line no-param-reassign
      itemChild.parameters = [];
      // eslint-disable-next-line no-param-reassign
      itemChild.parameters.push({
        isAlphanumeric: false,
        isField: true,
        order: 1,
        name: item.name,
        value: item.name,
      });
      this.transformationParameter = this.createColumnFunction(itemChild, item);
    },
    async openModalTransformations(itemChild, item) {
      await this.checkToken();

      if (itemChild) {
        this.transformationParameter = this.createColumnFunction(itemChild, item);
        setTimeout(() => {
          this.$emit('scrollComponent', true);
        }, 1000);

        if (this.transformationParameter) {
          switch(this.transformationParameter.template) {
            case 'depara':
              this.openDeparaModal(itemChild, item);
              break
            case 'depara_avancado':
              this.openAdvancedDeparaModal(item);
              break
            case 'concatenar_campos':
              if (!this.concatFunctionValidation(item)) {
                itemChild.isChecked = false;
                return;
              };
              this.resetOrderTransformations(item);
              this.openConcatColumnModel(item);
              break
            default:
              this.openDefaultTransformationModal(item);
              break
          }
        }
      }
    },
    openDeparaModal(itemChild, item) {
      if (!this.isEdit) {
        this.updateItemChildParameters(itemChild, item);
      }
      setTimeout(() => {
        this.$refs.transformationsFromToModal.open(item);
      }, 500);
    },
    openAdvancedDeparaModal(item) {
      setTimeout(() => {
        this.$refs.transformationsFromToAdvancedModal.open(item);
      }, 500);
    },
    openConcatColumnModel(item) {
      setTimeout(() => {
        this.$refs.transformationsConcatColumnModal.open(item);
      }, 500);
    },
    openDefaultTransformationModal(item) {
      setTimeout(() => {
        this.$refs.transformationModal.open(item);
      }, 250);
    },
    updateItemChildParameters(itemChild, item) {
      // eslint-disable-next-line no-param-reassign
      itemChild.parameters[0].isAlphanumeric = false;
      // eslint-disable-next-line no-param-reassign
      itemChild.parameters[0].isField = true;
      // eslint-disable-next-line no-param-reassign
      itemChild.parameters[0].order = 1;
      // eslint-disable-next-line no-param-reassign
      itemChild.parameters[0].name = item.name;
      // eslint-disable-next-line no-param-reassign
      itemChild.parameters[0].value = item.name;
    },
    createColumnFunction(itemChild, item) {
      const layoutColumnIndex = this.layout.columns.indexOf(item);
      if (layoutColumnIndex >= 0) {
        const transformationIndex = this.layout.columns[layoutColumnIndex].transformations.indexOf(itemChild);
        if (transformationIndex >= 0) {
          this.layout.columns[layoutColumnIndex].transformations[transformationIndex].template = itemChild.template ? itemChild.template : itemChild.id;
          this.layout.columns[layoutColumnIndex].transformations[transformationIndex].field = item.name;
          this.layout.columns[layoutColumnIndex].transformations[transformationIndex].id = null;
          return this.layout.columns[layoutColumnIndex].transformations[transformationIndex];
        }
      }
      return null;
    },
    addColumnFunctionFromModal(data) {
      const layoutColumn = lodash.cloneDeep(this.layout.columns);
      const itemTransformation = layoutColumn.find((column) => column.name === data.field).transformations.find((transformation) => transformation.name === data.name);
      if (itemTransformation.name === data.name) {
        const columnIndex = layoutColumn.indexOf(layoutColumn.find((column) => column.name === data.field));
        if (columnIndex >= 0) {
          itemTransformation.field = data.field;
          itemTransformation.order = data.order;
          itemTransformation.parameters = data.parameters;
          const transformationIndex = layoutColumn[columnIndex].transformations.indexOf(itemTransformation);
          if (transformationIndex >= 0) {
            layoutColumn[columnIndex].transformations[transformationIndex] = itemTransformation;
          }
        }
      }
    },
    async selectLoadedTransformations() {
      const layoutSession = JSON.parse(sessionStorage.getItem('layout'));
      this.layout.columns.forEach((column) => {
        if (layoutSession && (layoutSession.columns && layoutSession.columns.length > 0)) {
          const columnSelected = layoutSession.columns.find((columnSession) => columnSession.name === column.name);
          if (columnSelected && (column.transformations && column.transformations.length > 0)) {
            column.transformations.forEach((element, index) => {
              const transformationSelected = columnSelected.transformations.find((transformation) => transformation.template === element.id);
              if (transformationSelected) {
                const parameterMerge = [];
                this.validateTemplateCalculateDateAndRefactor(transformationSelected, column.transformations, index);

                this.mergeParameters(transformationSelected, column.transformations, index, parameterMerge);
                // eslint-disable-next-line no-param-reassign
                column.transformations[index] = {
                  ...column.transformations[index],
                  ...transformationSelected,
                  field: column.name,
                  isChecked: true,
                  parameters: transformationSelected.template !== 'depara'
                              && transformationSelected.template !== 'depara_avancado'
                              && transformationSelected.template !== 'concatenar_campos'
                              ? [...parameterMerge] : transformationSelected.parameters,
                };
              }
            });
          }
        }
      });
      this.overlay = false;
    },
    validateTemplateCalculateDateAndRefactor(transformationSelected, columnTransformations, index) {
      if (transformationSelected.template === 'calcula_data') {
        const parametersCalculaData = transformationSelected.parameters[0];
        const checkParameterIsField = columnTransformations[index].parameters.filter((param) => param.isField !== null && param.isField);
        if (checkParameterIsField.length === 0) {
          columnTransformations[index].parameters.unshift({
            isAlphanumeric: parametersCalculaData.isAlphanumeric,
            isField: true,
            order: 1,
            name: parametersCalculaData.value,
            value: parametersCalculaData.value,
          });
        }
        // eslint-disable-next-line no-param-reassign
        columnTransformations[index].parameters = columnTransformations[index].parameters.map((paramMap, indexMap) => ({
          ...paramMap,
          order: indexMap + 1,
        }));
        // eslint-disable-next-line no-param-reassign
        transformationSelected.parameters = transformationSelected.parameters.map((paramMap, indexMap) => ({
          ...paramMap,
          order: indexMap + 1,
        }));
      }
    },
    concatFunctionValidation(item) {
      if (this.layout.typeIntegrationValue === 'SHIPPING' && item.fromTo === 'no_reference' && this.layout.insuranceCarrierId) return true;
      else {
        this.$refs.SnackbarCustomize.open('error', `A função Concatenar Campos só pode ser utilizada em colunas do tipo Sem Referência.`,
                                                    10000);
        return false;
      }
    },
    concatFunctionValidationBtn(itemChild) {
      const templateField = itemChild.template ? itemChild.template : itemChild.id;
      return templateField === 'concatenar_campos' && this.layout.typeIntegrationValue !== 'SHIPPING';
    },
    mergeParameters(transformationSelected, columnTransformations, index, parameterMerge) {
      columnTransformations[index].parameters.forEach((parameter, i) => {
        if (
            transformationSelected.template !== 'regex'
            && transformationSelected.template !== 'depara'
            && transformationSelected.template !== 'depara_avancado'
            && transformationSelected.template !== 'concatenar_campos') {
          const parameterSelected = transformationSelected.parameters.find((parameterFind) => parameterFind.order === parameter.order);
          if (parameterSelected) {
            parameterMerge.push({
              ...columnTransformations[index].parameters[i],
              ...parameterSelected,
            });
          }
        } else if (transformationSelected.template === 'regex') {
          columnTransformations[index].parameters.sort((a, b) => this.sortByParamRegex(columnTransformations[index].parameters, a, b));
          const parameterSelected = transformationSelected.parameters.find((parameterFind) => parameterFind.order === parameter.order);
          parameterMerge.push({
            ...parameterSelected,
            ...columnTransformations[index].parameters[i],
            isAlphanumeric: parameterSelected.isAlphanumeric,
          });
        }
      });
    },
    isToDisableCheck(itemChild, item) {
      const isEqualFixedValue = item.transformations.find((transformation) => transformation.template === 'valor_fixo');
      const isDifferentFixedValue = item.transformations.find((transformation) => transformation.template !== 'valor_fixo');
      return (itemChild.template !== 'valor_fixo' && isEqualFixedValue && isEqualFixedValue.isChecked) || (itemChild.template === 'valor_fixo' && isDifferentFixedValue && isDifferentFixedValue.isChecked);
    },
    sortByParamRegex(parameters, a, b) {
      if (a.isField && !b.isField) {
        // eslint-disable-next-line no-param-reassign
        parameters[1].order = 1;
        // eslint-disable-next-line no-param-reassign
        parameters[0].order = 2;
        return -1;
      }
      return 0;
    },
    async checkToken() {
      const tokenActive = sessionStorage.getItem(this.SESSION_STORAGE_TOKEN) || sessionStorage.getItem(this.SESSION_ODOO_TOKEN) || '';
      if (this.rule.checkExpiredToken(tokenActive)) await this.refreshToken();
    },
    async refreshToken() {
      const TOKEN_PREFIX = 'Bearer ';
      observableMutationsRefreshToken.setLoadingRefreshToken(true);
      try {
        const response = await this.authService.GetTokenFullByRefresh();
        if (response && response.data) {
          const { token } = response.data;
          const fullToken = `${TOKEN_PREFIX}${token}`;
          sessionStorage.setItem(this.SESSION_STORAGE_TOKEN, fullToken);
          sessionStorage.setItem(this.SESSION_ODOO_TOKEN, fullToken);
          await this.loadUserSessionData();
        }
      } catch (error) {
        console.error('Error refreshing tokens:', error);
      } finally {
        observableMutationsRefreshToken.setLoadingRefreshToken(false);
      }
    },
    async loadUserSessionData() {
      this.userService = new UserService();
      await this.userService.GetCurrent().then((response) => {
        const session = response.data;
        if (session) {
          const userSessionStorage = {
            authenticated: session.authenticated,
            authorities: session.authorities,
            user: {
              name: session.principal && session.principal.user ? session.principal.user.name : null,
              email: session.name,
              financialGroupId: session.principal && session.principal.user ? session.principal.user.financialGroupExternalID : null,
              appCode: session.principal && session.principal.user ? session.principal.user.appCode : null,
            },
          };
          this.userUtils.createUserSessionStorage(userSessionStorage);
        }
      }).catch((error) => {
        if (error && error.response && error.response.status === 401) {
          this.$refs.SnackbarCustomize.open('error', 'Token inexistente');
        }
      });
    },
    /*
      reorder the order of transformations when the "Concatenar os Campos"
      option is selected, leaving "Concatenar os Campos" first.
    */
    resetOrderTransformations(item) {
      const columnIndex = this.layout.columns.findIndex(el => el.id === item.id);

      let columns = this.layout.columns;
      let transformations = columns[columnIndex].transformations;

      const concatFunctionIndex = transformations.findIndex(el => el.template === "concatenar_campos");
      if (concatFunctionIndex === -1) return;
      this.rearrangeTransformations(transformations, columnIndex, concatFunctionIndex);
    },
    /*
      defines list of transformations with defined order
    */
    setTransformationsWithOrder(columnIndex, transformationsOrdered) {
      return this.layout.columns[columnIndex].transformations
        .map((transformation, index) => ({ order: transformation.order, index }))
        .filter(transformation => transformation.order && !transformationsOrdered.includes(transformation.index))
        .sort((a, b) => a.order - b.order);
    },
    // performs the reordering
    rearrangeTransformations(transformations, columnIndex, concatFunctionIndex) {
      let count = 1;
      let transformationsOrdened = [concatFunctionIndex];
      transformations[concatFunctionIndex].order = 1;
      this.$forceUpdate();

      while (count !== false) {
        let transformationOrders = this.setTransformationsWithOrder(columnIndex, transformationsOrdened);
        const transformationOrder = transformationOrders.find(el => el.order === count.toString());

        if (Boolean(transformationOrder)) {
          transformations[transformationOrder.index].order = `${count+1}`
          transformationOrders = this.setTransformationsWithOrder(columnIndex, transformationsOrdened);
          if (!this.checkTransformationsWithSameValue(transformationOrders, count, transformationsOrdened)) {
            transformationsOrdened.push(transformationOrder.index)
            count = count+1;
          }
        } else {
          count = false;
        }
      }
    },
    // check if there are still any orders with the same value
    checkTransformationsWithSameValue(transformationOrders, count, transformationsOrdened) {
      return transformationOrders.some((el) => el.order === `${count}` && transformationsOrdened.every(item => item !== el.index));
    }
  },

  created() {
    this.rule = new Rules();
    this.functionService = new FunctionService();
    this.dataSourceService = new DataSourceService();
    this.authService = new AuthService();
    this.userUtils = new UserUtils();
  },
});
</script>

<style scoped>
.isSelectedTransformation {
  background-color: rgba(234, 73, 101, 0.15);
  color: #ea4965;
}
</style>
